/* stylelint-disable no-duplicate-selectors */
@import 'styles/helpers';

.wrap {
  padding: 140px 0 77px;
  background-color: #001536;
  color: #fff;
}

.title {
  color: #fff;
}

:global(.mobile) {
  .wrap {
    @include px2rem(padding, 160, 0, 0);
  }
}

.inner {
  position: relative;
  @include inner;
}

:global(.mobile) {
  .inner {
    height: auto;
  }
}

.content {
  margin: 0 0 90px;
  text-align: center;
}

:global(.mobile) {
  .content {
    @include px2rem(margin-bottom, 100);
  }
}

@include contentTitle;

$itemsDur: 0.4;
$itemDur: 0.4;
$itemDelay: $itemsDur + 0.1;
$itemIconSubDur: 0.4;
$itemIconSubDelay: $itemDelay + $itemDur - 0.2;

.items {
  display: flex;
  justify-content: center;

  opacity: 0;
  transform: translateY(12px);
  @include transition($itemsDur, transform, opacity);
}

:global(.mobile) {
  .items {
    display: block;
    @include px2rem(transform, 'translateY(', 30, ')');
  }
}

.innerEnter .items {
  transform: translateY(0);
  opacity: 1;
}

:global(.mobile) {
  .inner {
    padding-bottom: 50px;
  }
}

.item {
  text-align: center;
  max-width: 360px;

  &:first-child {
    margin-right: 243px;
  }
}

:global(.mobile) {
  .item {
    max-width: none;
    margin: auto;
    &:first-child {
      @include px2rem(margin-bottom, 140);
    }
  }
}

.itemThumb {
  width: 360px;
  height: 350px;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
  }
}

.itemTitle {
  font-size: 18px;
  font-weight: 700;
  margin: 33px 0 18px;
}

:global(.mobile) {
  .itemTitle {
    margin-top: 24px;
    @include px2rem(font-size, 32);
    @include px2rem(margin-bottom, 10);
  }

  .itemThumb {
    margin: 0 auto;
    height: 3.241rem;
    width: 3.333rem;
  }
}

.itemDesc {
  font-size: 14px;
  opacity: 0.7;
  line-height: 1.71;
}

:global(.mobile) {
  .itemDesc {
    @include px2rem(font-size, 28);
  }
}

.safeIcon {
  position: absolute;
  top: 9px;
  right: -128px;
  @include bgimg('st-home-ic-security-safe-2.png', 100px, 100px);
}

.lockIcon {
  position: absolute;
  bottom: -70px;
  left: -165px;
  @include bgimg('st-home-ic-security-lock.png', 100px, 100px);
}
