@import 'styles/helpers';

.wrap {
  background-color: #f9fbfe;
  padding-bottom: 140px;
}

.inner {
  position: relative;
  @include inner;
  padding-top: 45px;

  .content {
    @include contentTitle;
    text-align: center;

    .title {
      margin: 100px 0 16px;
    }

    .subtitle {
      font-size: 14px;
    }
  }

  .panels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 68px auto 0;

    .panel {
      opacity: 0;
      transform: translateY(12px);
      transition: 0.4s opacity, 0.4s transform;
      height: 384px;
      width: 552px;
      background-color: #fff;
      padding: 50px 36px 40px 36px;
      border-radius: 12px;
      text-align: center;
      margin-bottom: 24px;
    }

    .panelImage {
      margin: 0 auto 26px auto;
      width: 240px;
      height: 180px;
      position: relative;

      > div {
        width: 100%;
        height: 100%;
      }
    }

    .panelTitle {
      font-size: 18px;
      font-weight: 700;
    }

    .panelDescription {
      margin-top: 16px;
      font-size: 14px;
      opacity: 0.7;
    }
  }

  .details {
    display: table;
    padding-left: 26px;
    padding-right: 26px;
    margin: 56px auto 0 auto;
    height: 48px;
    border-radius: 24px;
    background-image: linear-gradient(153deg, #3ac4fb -30%, #178bec 141%);
    text-align: center;
    font-size: 18px;
    padding-top: 11px;
    color: #ffffff;
    font-weight: 600;
    transition: transform 0.3s;
    cursor: pointer;

    &:hover {
      @include dllinkHover;
      transform: translateY(-2px);
    }
  }
}

.innerEnter {
  .panels {
    .panel {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.phoneIcon {
  @include bgimg('st-home-ic-allin-phone.png', 200px, 180px);
  position: absolute;
  top: 40px;
  left: -32px;
  z-index: 24;
}

.picIcon {
  @include bgimg('st-home-ic-allin-pic.svg', 200px, 180px);
  position: absolute;
  top: 21px;
  left: -271px;
}

.messageIcon {
  @include bgimg('st-home-ic-allin-message.svg', 200px, 180px);
  position: absolute;
  top: 21px;
  right: -286px;
}

.dateIcon {
  @include bgimg('st-home-ic-allin-date.svg', 200px, 180px);
  position: absolute;
  bottom: 205px;
  left: -281px;
}

.safeIcon {
  @include bgimg('st-home-ic-allin-safe.png', 200px, 180px);
  position: absolute;
  bottom: -111px;
  left: 62px;
}

.chatIcon {
  @include bgimg('st-home-ic-allin-chat.png', 200px, 180px);
  position: absolute;
  bottom: -30px;
  right: -177px;
}

:global(.mobile) {
  .wrap {
    padding-bottom: 72px;

    .inner {
      padding-top: 80px;
      margin-left: 0 !important;
      margin-right: 0 !important;

      .content {
        .title {
          margin-top: 0;
        }

        .subtitle {
          @include px2rem(font-size, 28);
          text-align: center;
          width: auto;
          opacity: 0.7;
        }
      }

      .panels {
        margin-top: 40px;

        .panel {
          height: auto;
          @include px2rem(padding-top, 48);
          @include px2rem(padding-left, 40);
          @include px2rem(padding-right, 40);
          @include px2rem(padding-bottom, 48);
          margin-right: 0;
          width: 100%;

          .panelImage {
            width: 2.963rem;
            height: 2.222rem;
          }

          .panelTitle {
            @include px2rem(font-size, 32);
          }

          .panelDescription {
            margin-top: 8px;
            @include px2rem(font-size, 28);
          }
        }
      }
    }

    .phoneIcon {
      top: 0;
      left: -11px;
      width: 100px;
      height: 90px;
    }

    .safeIcon {
      height: 90px;
      width: 100px;
      left: -18px;
      bottom: -7px;
    }

    .chatIcon {
      height: 90px;
      width: 100px;
      bottom: -37px;
      right: -31px;
    }

    .dateIcon,
    .messageIcon,
    .picIcon {
      display: none;
    }

    .details {
      margin-top: 16px;
    }
  }
}
