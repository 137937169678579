@import 'styles/helpers';

.oneStopToWorkWrapper {
  margin-top: -64px;
  padding-bottom: 80px;
  position: relative;

  .oneStopToWorkWrapperBackground {
    position: absolute;
    margin-top: -64px;
    left: 0;
    width: 100vw;
    height: 900px;
    overflow: hidden;

    svg {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }

  .oneStopToWorkWrapperInnerBackground {
    width: 1525px;
    height: 732px;
    position: absolute;
    top: 55px;
    left: calc(50% - 760px);
  }

  .oneStopToWorkWrapperInner {
    @include inner;
    height: 750px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      flex: 1;
      @include contentTitle;
      opacity: 0;
      margin-bottom: 0;
      transition: opacity 0.3s, transform 0.3s;
      z-index: 1;

      .title {
        font-size: 48px;
        margin-top: 32px;
        color: white;
        min-width: 480px;
      }

      .subtitle {
        font-size: 18px;
        line-height: 36px;
        margin-top: 16px;
        color: white;
      }
    }

    .homeBanner {
      flex: 1;
      transition: 0.3s opacity 0.3s;
      opacity: 0;
      margin: 40px 0 0;
    }

    .bannerImage {
      width: 815px;
      height: 465px;
      position: relative;

      > div {
        width: 100%;
        height: 100%;
      }
    }

    .mobileSIllustrator {
      display: none;
    }
  }

  .oneStopToWorkWrapperInnerEnter .content,
  .homeBannerEnter {
    opacity: 1 !important;
  }
}

.oneStopToWorkBtn {
  display: inline-flex;
  align-items: center;
  margin: 48px 0.155px 0;
  height: 48px;
  border-radius: 24px;
  border: 1px solid white;
  text-align: center;
  font-size: 18px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 600;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    @include dllinkHover;
    transform: translateY(-2px);
  }
}

.btnSection {
  white-space: nowrap;
}

.startBtn {
  composes: oneStopToWorkBtn;
  background-color: white;
  color: #1b92f5;
  margin-right: 24px;

  &:hover {
    color: #1b92f5 !important;
  }
}

.contactSaleBtn {
  composes: oneStopToWorkBtn;
  background-color: transparent;
  color: white;
}

@media (max-width: $screen-lg) {
  .oneStopToWorkWrapperInner {
    .bannerImage {
      width: 540px !important;
      height: 308px !important;
    }
  }
}

:global(.mobile) {
  .oneStopToWorkWrapper {
    padding-top: 100px;
    padding-bottom: 90px;

    .oneStopToWorkWrapperBackground {
      height: 100%;
      margin-top: -96px;
      z-index: -1;
      overflow: visible;
      display: flex;
      justify-content: center;

      svg {
        height: 100%;
        width: 150%;
        margin-left: 20%;
      }
    }

    .oneStopToWorkWrapperInnerBackground {
      display: none;
    }

    .oneStopToWorkWrapperInner {
      height: auto;
      display: block;
      margin-left: 0 !important;
      margin-right: 0 !important;

      .content {
        max-width: none;
        min-height: 0;
        text-align: center;

        .title {
          margin: 16px 0 8px 0;
          min-width: 0;
          @include px2rem(font-size, 48);
        }

        .subtitle {
          width: auto;
          font-size: 16px;
          margin-bottom: 40px;
          line-height: 1.5;
        }

        .btnSection {
          white-space: unset;
          margin-left: -0.4rem;
          margin-right: -0.4rem;
        }

        .oneStopToWorkBtn {
          font-size: 16px !important;
          height: 40px !important;
          margin: 8px !important;
          margin-top: 0 !important;
          margin-bottom: 20px !important;
        }
      }

      .homeBanner {
        position: relative;
        right: unset;
        background: none;
        display: flex;
        justify-content: center;
        margin-top: 44px !important;
      }

      .bannerImage {
        width: 80vw !important;
        height: 56vw !important;
      }

      .mobileSIllustrator {
        display: unset;
        position: absolute;
        bottom: 10px;
        left: calc(100% - 18vw);
        width: 32vw !important;
        height: 47vw !important;

        > div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
