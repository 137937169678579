@import 'styles/helpers';

.transition {
  @include bgimg('st-home-bg-wave-dark.png', 2560px, 154px);
  min-width: 100%;
  margin: -60px 0 50px 0;
  z-index: 2;
}

:global(.mobile) {
  .transition {
    margin-bottom: 14px;
    margin-top: -103px;
  }
}
