@import 'styles/helpers';

//st-home-img-video-bordertopright.png
//st-home-img-video-borderbottomleft.png
// st-home-img-video-dots.png

.homeVideoWrapper {
  padding: 140px 0 80px;
  position: relative;

  .illustratorRight {
    width: 978px;
    height: 824px;
    position: absolute;
    top: 85px;
    right: -840px;
    z-index: -1;
  }

  .homeVideoWrapperInner {
    position: relative;
    @include inner;

    .content {
      @include contentTitle;
      text-align: center;
      position: relative;

      .title {
        margin-bottom: 80px !important;
      }

      .illustratorCenter {
        width: 978px;
        height: 824px;
        position: absolute;
        top: -25px;
        left: -185px;
        z-index: -1;
      }

      .videoWrapper {
        position: relative;
        margin: 0 auto;
        width: 1016px;
        height: 571.5px;
        opacity: 0;
        transform: translateY(12px);
        transition: 0.4s opacity, 0.4s transform;

        .topRightCorner {
          @include bgimg('st-home-img-video-bordertopright.png', 46px, 121px);
          position: absolute;
          top: -22px;
          left: calc(100% - 22px);
          z-index: -1;
        }

        .bottomLeftCorner {
          @include bgimg('st-home-img-video-borderbottomleft.png', 81px, 121px);
          position: absolute;
          top: calc(100% - 95px);
          left: -26px;
          z-index: -1;
        }

        .playButton {
          position: absolute;
          @include bgimg('st-home-img-video-play.png', 76px, 76px);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          cursor: pointer;
        }

        > div[data-vjs-player='true'] {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .homeVideoWrapperInnerEnter .content .videoWrapper {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
}

:global(.mobile) {
  .homeVideoWrapper {
    padding-top: 90px;
    padding-bottom: 40px;

    .content {
      .title {
        margin-bottom: 40px !important;
      }
    }

    .illustratorCenter,
    .illustratorRight,
    .bottomLeftCorner {
      display: none;
    }

    .topRightCorner {
      width: 24px !important;
      height: 64px !important;
      top: -8px !important;
      left: calc(100% - 16px) !important;
    }

    .videoWrapper {
      width: 90vw !important;
      height: 50.6vw !important;
    }
  }
}
